export class BrokersClickFakeData {
  public static brokers = [
    {
      id: 1,
      fullName: 'Dingus',
      mkp: '20%',
      mkpapp: '20%',
      mko: '0%',
      comimision: '0%',
      status: 'inactive',
    },
    {
      id: 2,
      fullName: 'Omnibees',
      mkp: '20%',
      mkpapp: '20%',
      mko: '0%',
      comimision: '0%',
      status: 'pending',
    },
    {
      id: 3,
      fullName: 'escalabeds',
      mkp: '20%',
      mkpapp: '20%',
      mko: '0%',
      comimision: '0%',
      status: 'active',
    },
    {
      id: 4,
      fullName: 'Nutiee',
      mkp: '20%',
      mkpapp: '20%',
      mko: '0%',
      comimision: '0%',
      status: 'inactive',
    },
    {
      id: 5,
      fullName: 'Hotel do',
      mkp: '20%',
      mkpapp: '20%',
      mko: '0%',
      comimision: '0%',
      status: 'pending',
    },
    {
      id: 6,
      fullName: 'Hotel beds',
      mkp: '20%',
      mkpapp: '20%',
      mko: '0%',
      comimision: '0%',
      status: 'active',
    },
    {
      id: 7,
      fullName: 'Expedia',
      mkp: '20%',
      mkpapp: '20%',
      mko: '0%',
      comimision: '0%',
      status: 'pending',
    },
    {
      id: 8,
      fullName: 'Booko pro',
      mkp: '20%',
      mkpapp: '20%',
      mko: '0%',
      comimision: '0%',
      status: 'pending',
    },
    {
      id: 9,
      fullName: 'Jumbo',
      mkp: '20%',
      mkpapp: '20%',
      mko: '0%',
      comimision: '0%',
      status: 'pending',
    },
    {
      id: 10,
      fullName: ' Restel',
      mkp: '20%',
      mkpapp: '20%',
      mko: '0%',
      comimision: '0%',
      status: 'inactive',
    },
    {
      id: 11,
      fullName: 'Dingus',
      mkp: '20%',
      mkpapp: '20%',
      mko: '0%',
      comimision: '0%',
      status: 'inactive',
    },
    {
      id: 12,
      fullName: 'Hotel beds',
      mkp: '20%',
      mkpapp: '20%',
      mko: '0%',
      comimision: '0%',
      status: 'pending',
    }
  ];
}
