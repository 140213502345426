<!-- Footer -->
<p class="clearfix text-center mb-0">
  <span>
    &copy; Click Operadora Turística. Todos los derechos reservados.
  </span>
  <!-- <span class="float-md-left d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; {{ year }}
    <span class="d-none d-sm-inline-block">, {{ coreConfig.app.appName }} Turistica, Todos los derechos reservados</span>
  </span>
  <span class="float-md-right d-none d-md-block">
    Todos los derechos reservados <span [data-feather]="'heart'" [class]="'pink'"></span>
  </span> -->
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>
