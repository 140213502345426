import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  {
    id: 'apps',
    type: 'section',
    title: '',
    translate: 'MENU.APPS.SECTION',
    icon: 'package',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: 'home',
        url: 'dashboard'
      },
      {
        id: 'operator',
        title: 'Operador',
        type: 'collapsible',
        icon: 'user',
        children: [
          {
            id: 'operator-general-data',
            title: 'Datos Generales',
            type: 'item',
            icon: 'minus',
            url: 'operator/general-data',
          },
          {
            id: 'operator-usuarios',
            title: 'Usuarios',
            type: 'item',
            icon: 'minus',
            url: 'operator/user/list',
          },
          {
            id: 'operator-imagen',
            title: 'Imagen',
            type: 'item',
            icon: 'minus',
            url: 'operator/list',
          },
          {
            id: 'operator-editor',
            title: 'Editor de texto',
            type: 'item',
            icon: 'minus',
            url: 'operator/text-editor/agency-registration',
          },
          {
            id: 'operator-brokers',
            title: 'Brokers',
            type: 'item',
            icon: 'minus',
            url: 'brokers/list',
          },
          {
            id: 'operator-servicios',
            title: 'Servicios',
            type: 'item',
            icon: 'minus',
            url: 'operator/service',
          }
        ]
      },
      {
        id: 'branch-offices',
        title: 'Sucursales',
        type: 'item',
        icon: 'map-pin',
        url: 'branch-offices/list'
      },
      {
        id: 'agencies',
        title: 'Agencias',
        type: 'collapsible',
        icon: 'user',
        children: [
          {
            id: 'agencies-list',
            title: 'Listado',
            type: 'item',
            icon: 'minus',
            url: 'agencies/list',
          },
          {
            id: 'agencies-data',
            title: 'Datos Generales',
            type: 'item',
            icon: 'minus',
            url: '',
          },
          {
            id: 'agencies-data',
            title: 'Datos Generales',
            type: 'item',
            icon: 'minus',
            url: '',
          },
          {
            id: 'agencies-data',
            title: 'Datos Generales',
            type: 'item',
            icon: 'minus',
            url: '',
          },
          {
            id: 'agencies-data',
            title: 'Datos Generales',
            type: 'item',
            icon: 'minus',
            url: '',
          },
          {
            id: 'agencies-data',
            title: 'Datos Generales',
            type: 'item',
            icon: 'minus',
            url: '',
          },
        ]
      },
      {
        id: 'currencies',
        title: 'Monedas',
        type: 'item',
        icon: 'dollar-sign',
        url: 'currencies/list'
      },
      {
        id: 'brokers',
        title: 'Brokers',
        type: 'item',
        icon: 'user',
        url: 'brokers/list'
      },
      {
        id: 'hotels',
        title: 'Hoteles',
        type: 'item',
        icon: 'map-pin',
        url: 'hotels/list'
      }
    ]
  }
];
