export class UserClickFakeData {
  public static user = [
    {
      id: 1,
      fullName: 'Galen Slixby',
      role: 'Editor',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      user: 'demo'
    },
    {
      id: 2,
      fullName: 'Halsey Redmore',
      role: 'Author',
      email: 'hredmore1@imgur.com',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 3,
      fullName: 'Marjory Sicely',
      role: 'Maintainer',
      email: 'msicely2@who.int',
      status: 'active',
      user: 'demo'
    },
    {
      id: 4,
      fullName: 'Cyrill Risby',
      role: 'Maintainer',
      email: 'crisby3@wordpress.com',
      status: 'inactive',
      user: 'demo'
    },
    {
      id: 5,
      fullName: 'Maggy Hurran',
      role: 'Subscriber',
      email: 'mhurran4@yahoo.co.jp',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 6,
      fullName: 'Silvain Halstead',
      role: 'Author',
      email: 'shalstead5@shinystat.com',
      status: 'active',
      user: 'demo'
    },
    {
      id: 7,
      fullName: 'Breena Gallemore',
      role: 'Subscriber',
      email: 'bgallemore6@boston.com',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 8,
      fullName: 'Kathryne Liger',
      role: 'Author',
      email: 'kliger7@vinaora.com',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 9,
      fullName: 'Franz Scotfurth',
      role: 'Subscriber',
      email: 'fscotfurth8@dailymotion.com',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 10,
      fullName: 'Jillene Bellany',
      role: 'Maintainer',
      email: 'jbellany9@kickstarter.com',
      status: 'inactive',
      user: 'demo'
    },
    {
      id: 11,
      fullName: 'Jonah Wharlton',
      role: 'Subscriber',
      email: 'jwharltona@oakley.com',
      status: 'inactive',
      user: 'demo'
    },
    {
      id: 12,
      fullName: 'Seth Hallam',
      role: 'Subscriber',
      email: 'shallamb@hugedomains.com',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 13,
      fullName: 'Yoko Pottie',
      role: 'Subscriber',
      email: 'ypottiec@privacy.gov.au',
      status: 'inactive',
      user: 'demo'
    },
    {
      id: 14,
      fullName: 'Maximilianus Krause',
      role: 'Author',
      email: 'mkraused@stanford.edu',
      status: 'active',
      user: 'demo'
    },
    {
      id: 15,
      fullName: 'Zsazsa McCleverty',
      role: 'Maintainer',
      email: 'zmcclevertye@soundcloud.com',
      status: 'active',
      user: 'demo'
    },
    {
      id: 16,
      fullName: 'Bentlee Emblin',
      role: 'Author',
      email: 'bemblinf@wired.com',
      status: 'active',
      user: 'demo'
    },
    {
      id: 17,
      fullName: 'Brockie Myles',
      role: 'Maintainer',
      email: 'bmylesg@amazon.com',
      status: 'active',
      user: 'demo'
    },
    {
      id: 18,
      fullName: 'Bertha Biner',
      role: 'Editor',
      email: 'bbinerh@mozilla.com',
      status: 'active',
      user: 'demo'
    },
    {
      id: 19,
      fullName: 'Travus Bruntjen',
      role: 'Admin',
      email: 'tbruntjeni@sitemeter.com',
      status: 'active',
      user: 'demo'
    },
    {
      id: 20,
      fullName: 'Wesley Burland',
      role: 'Editor',
      email: 'wburlandj@uiuc.edu',
      status: 'inactive',
      user: 'demo'
    },
    {
      id: 21,
      fullName: 'Stu Delamaine',
      role: 'Author',
      email: 'sdelamainek@who.int',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 22,
      fullName: 'Jameson Lyster',
      role: 'Editor',
      email: 'jlysterl@guardian.co.uk',
      status: 'inactive',
      user: 'demo'
    },
    {
      id: 23,
      fullName: 'Kare Skitterel',
      role: 'Maintainer',
      email: 'kskitterelm@washingtonpost.com',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 24,
      fullName: 'Cleavland Hatherleigh',
      role: 'Admin',
      email: 'chatherleighn@washington.edu',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 25,
      fullName: 'Adeline Micco',
      role: 'Admin',
      email: 'amiccoo@whitehouse.gov',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 26,
      fullName: 'Hugh Hasson',
      role: 'Admin',
      email: 'hhassonp@bizjournals.com',
      status: 'inactive',
      user: 'demo'
    },
    {
      id: 27,
      fullName: 'Germain Jacombs',
      role: 'Editor',
      email: 'gjacombsq@jigsy.com',
      status: 'active',
      user: 'demo'
    },
    {
      id: 28,
      fullName: 'Bree Kilday',
      role: 'Maintainer',
      email: 'bkildayr@mashable.com',
      status: 'active',
      user: 'demo'
    },
    {
      id: 29,
      fullName: 'Candice Pinyon',
      role: 'Maintainer',
      email: 'cpinyons@behance.net',
      status: 'active',
      user: 'demo'
    },
    {
      id: 30,
      fullName: 'Isabel Mallindine',
      role: 'Subscriber',
      email: 'imallindinet@shinystat.com',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 31,
      fullName: 'Gwendolyn Meineken',
      role: 'Admin',
      email: 'gmeinekenu@hc360.com',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 32,
      fullName: 'Rafaellle Snowball',
      role: 'Editor',
      email: 'rsnowballv@indiegogo.com',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 33,
      fullName: 'Rochette Emer',
      role: 'Admin',
      email: 'remerw@blogtalkradio.com',
      status: 'active',
      user: 'demo'
    },
    {
      id: 34,
      fullName: 'Ophelie Fibbens',
      role: 'Subscriber',
      email: 'ofibbensx@booking.com',
      status: 'active',
      user: 'demo'
    },
    {
      id: 35,
      fullName: 'Stephen MacGilfoyle',
      role: 'Maintainer',
      email: 'smacgilfoyley@bigcartel.com',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 36,
      fullName: 'Bradan Rosebotham',
      role: 'Subscriber',
      email: 'brosebothamz@tripadvisor.com',
      status: 'inactive',
      user: 'demo'
    },
    {
      id: 37,
      fullName: 'Skip Hebblethwaite',
      role: 'Admin',
      email: 'shebblethwaite10@arizona.edu',
      status: 'inactive',
      user: 'demo'
    },
    {
      id: 38,
      fullName: 'Moritz Piccard',
      role: 'Maintainer',
      email: 'mpiccard11@vimeo.com',
      status: 'inactive',
      user: 'demo'
    },
    {
      id: 39,
      fullName: 'Tyne Widmore',
      role: 'Subscriber',
      email: 'twidmore12@bravesites.com',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 40,
      fullName: 'Florenza Desporte',
      role: 'Author',
      email: 'fdesporte13@omniture.com',
      status: 'active',
      user: 'demo'
    },
    {
      id: 41,
      fullName: 'Edwina Baldetti',
      role: 'Maintainer',
      email: 'ebaldetti14@theguardian.com',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 42,
      fullName: 'Benedetto Rossiter',
      role: 'Editor',
      email: 'brossiter15@craigslist.org',
      status: 'inactive',
      user: 'demo'
    },
    {
      id: 43,
      fullName: 'Micaela McNirlan',
      role: 'Admin',
      email: 'mmcnirlan16@hc360.com',
      status: 'inactive',
      user: 'demo'
    },
    {
      id: 44,
      fullName: 'Vladamir Koschek',
      role: 'Author',
      email: 'vkoschek17@abc.net.au',
      status: 'active',
      user: 'demo'
    },
    {
      id: 45,
      fullName: 'Corrie Perot',
      role: 'Subscriber',
      email: 'cperot18@goo.ne.jp',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 46,
      fullName: 'Saunder Offner',
      role: 'Maintainer',
      email: 'soffner19@mac.com',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 47,
      fullName: 'Karena Courtliff',
      role: 'Admin',
      email: 'kcourtliff1a@bbc.co.uk',
      status: 'active',
      user: 'demo'
    },
    {
      id: 48,
      fullName: 'Onfre Wind',
      role: 'Admin',
      email: 'owind1b@yandex.ru',
      status: 'pending',
      user: 'demo'
    },
    {
      id: 49,
      fullName: 'Paulie Durber',
      role: 'Subscriber',
      email: 'pdurber1c@gov.uk',
      status: 'inactive',
      user: 'demo'
    },
    {
      id: 50,
      fullName: 'Beverlie Krabbe',
      role: 'Editor',
      email: 'bkrabbe1d@home.pl',
      status: 'active',
      user: 'demo'
    }
  ];
}
