export class AgenciesFakeData {
  public static agencies = [
    {
      id: 1,
      fullName: 'Galen Slixby',
      role: 'Editor',
      country: 'El Salvador',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'inactive',
    },
    {
      id: 2,
      fullName: 'Halsey Redmore',
      role: 'Author',
      country: 'Albania',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 3,
      fullName: 'Marjory Sicely',
      role: 'Maintainer',
      country: 'Russia',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'active',
    },
    {
      id: 4,
      fullName: 'Cyrill Risby',
      role: 'Maintainer',
      country: 'China',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'inactive',
    },
    {
      id: 5,
      fullName: 'Maggy Hurran',
      role: 'Subscriber',
      country: 'Pakistan',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 6,
      fullName: 'Silvain Halstead',
      role: 'Author',
      country: 'China',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'active',
    },
    {
      id: 7,
      fullName: 'Breena Gallemore',
      role: 'Subscriber',
      country: 'Canada',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 8,
      fullName: 'Kathryne Liger',
      role: 'Author',
      country: 'France',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 9,
      fullName: 'Franz Scotfurth',
      role: 'Subscriber',
      country: 'China',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 10,
      fullName: 'Jillene Bellany',
      role: 'Maintainer',
      country: 'Jamaica',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'inactive',
    },
    {
      id: 11,
      fullName: 'Jonah Wharlton',
      role: 'Subscriber',
      country: 'United States',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'inactive',
    },
    {
      id: 12,
      fullName: 'Seth Hallam',
      role: 'Subscriber',
      country: 'Peru',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 13,
      fullName: 'Yoko Pottie',
      role: 'Subscriber',
      country: 'Philippines',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'inactive',
    },
    {
      id: 14,
      fullName: 'Maximilianus Krause',
      role: 'Author',
      country: 'Democratic Republic of the Congo',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'active',
    },
    {
      id: 15,
      fullName: 'Zsazsa McCleverty',
      role: 'Maintainer',
      country: 'France',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'active',
    },
    {
      id: 16,
      fullName: 'Bentlee Emblin',
      role: 'Author',
      country: 'Spain',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'active',
    },
    {
      id: 17,
      fullName: 'Brockie Myles',
      role: 'Maintainer',
      country: 'Poland',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'active',
    },
    {
      id: 18,
      fullName: 'Bertha Biner',
      role: 'Editor',
      country: 'Yemen',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'active',
    },
    {
      id: 19,
      fullName: 'Travus Bruntjen',
      role: 'Admin',
      country: 'France',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'active',
    },
    {
      id: 20,
      fullName: 'Wesley Burland',
      role: 'Editor',
      country: 'Honduras',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'inactive',
    },
    {
      id: 21,
      fullName: 'Stu Delamaine',
      role: 'Author',
      country: 'South Africa',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 22,
      fullName: 'Jameson Lyster',
      role: 'Editor',
      country: 'Ukraine',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'inactive',
    },
    {
      id: 23,
      fullName: 'Kare Skitterel',
      role: 'Maintainer',
      country: 'Poland',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 24,
      fullName: 'Cleavland Hatherleigh',
      role: 'Admin',
      country: 'Brazil',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 25,
      fullName: 'Adeline Micco',
      role: 'Admin',
      country: 'France',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 26,
      fullName: 'Hugh Hasson',
      role: 'Admin',
      country: 'China',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'inactive',
    },
    {
      id: 27,
      fullName: 'Germain Jacombs',
      role: 'Editor',
      country: 'Zambia',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'active',
    },
    {
      id: 28,
      fullName: 'Bree Kilday',
      role: 'Maintainer',
      country: 'Portugal',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'active',
    },
    {
      id: 29,
      fullName: 'Candice Pinyon',
      role: 'Maintainer',
      country: 'Sweden',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'active',
    },
    {
      id: 30,
      fullName: 'Isabel Mallindine',
      role: 'Subscriber',
      country: 'Slovenia',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 31,
      fullName: 'Gwendolyn Meineken',
      role: 'Admin',
      country: 'Moldova',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 32,
      fullName: 'Rafaellle Snowball',
      role: 'Editor',
      country: 'Philippines',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 33,
      fullName: 'Rochette Emer',
      role: 'Admin',
      country: 'North Korea',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'active',
    },
    {
      id: 34,
      fullName: 'Ophelie Fibbens',
      role: 'Subscriber',
      country: 'Indonesia',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'active',
    },
    {
      id: 35,
      fullName: 'Stephen MacGilfoyle',
      role: 'Maintainer',
      country: 'Japan',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 36,
      fullName: 'Bradan Rosebotham',
      role: 'Subscriber',
      country: 'Belarus',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'inactive',
    },
    {
      id: 37,
      fullName: 'Skip Hebblethwaite',
      role: 'Admin',
      country: 'Canada',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'inactive',
    },
    {
      id: 38,
      fullName: 'Moritz Piccard',
      role: 'Maintainer',
      country: 'Croatia',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'inactive',
    },
    {
      id: 39,
      fullName: 'Tyne Widmore',
      role: 'Subscriber',
      country: 'Finland',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 40,
      fullName: 'Florenza Desporte',
      role: 'Author',
      country: 'Ukraine',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'active',
    },
    {
      id: 41,
      fullName: 'Edwina Baldetti',
      role: 'Maintainer',
      country: 'Haiti',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 42,
      fullName: 'Benedetto Rossiter',
      role: 'Editor',
      country: 'Indonesia',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'inactive',
    },
    {
      id: 43,
      fullName: 'Micaela McNirlan',
      role: 'Admin',
      country: 'Indonesia',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'inactive',
    },
    {
      id: 44,
      fullName: 'Vladamir Koschek',
      role: 'Author',
      country: 'Guatemala',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'active',
    },
    {
      id: 45,
      fullName: 'Corrie Perot',
      role: 'Subscriber',
      country: 'China',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 46,
      fullName: 'Saunder Offner',
      role: 'Maintainer',
      country: 'Poland',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 47,
      fullName: 'Karena Courtliff',
      role: 'Admin',
      country: 'China',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'active',
    },
    {
      id: 48,
      fullName: 'Onfre Wind',
      role: 'Admin',
      country: 'Ukraine',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'pending',
    },
    {
      id: 49,
      fullName: 'Paulie Durber',
      role: 'Subscriber',
      country: 'Sweden',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'inactive',
    },
    {
      id: 50,
      fullName: 'Beverlie Krabbe',
      role: 'Editor',
      country: 'China',
      operador: 'Click',
      category: 'Silver',
      datecreate: '2023/01/30',
      status: 'active',
    }
  ];
}
